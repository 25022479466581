import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FlagFR = () => {
  const data = useStaticQuery(graphql`
    query {
      bottle: file(relativePath: { eq: "flag.png" }) {
        childImageSharp {
          fixed(height: 10) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  if (!data?.bottle?.childImageSharp?.fixed) {
    return <div>Picture not found</div>
  }

  return <Img fixed={data.bottle.childImageSharp.fixed} alt="drapeau français" />
}

export default FlagFR
