import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"

import FlagFR from "../../components/images/flag-fr"
import Bottle from "../../components/images/bottle-product"
import Flowers from "../../components/images/flowers-in-bag"
import CanProduct from "../../components/images/can-product"
import Can from "../../components/images/can"

import "./products.css"

export default function Products() {
  return (
    <section id="products" className="section-wrapper">
      <h1 className="section-title">Nos produits</h1>

      <main>
        <article>
          <Bottle />
          <h1>ibahis · la bouteille</h1>
          <p>
            Boisson gazeuse à base d’infusion de fleurs d’hibiscus. <br />
            100% naturelle, IBAHIS est sans colorant et sans conservateur !<br />
          </p>
          <div className="notice-text">
            <FlagFR />
            <small>
              Toutes nos bouteilles sont certifiées <i>made in France</i>
            </small>
          </div>
        </article>

        <article>
          <CanProduct />
          <h1>ibahis · la canette</h1>
          <p>
            Découvrez notre nouveau format canette !
          </p>
        </article>

        <article>
          <Can />
          <h1>ibahis · la version citron gimgenbre !</h1>
          <p>
            Découvrez notre nouvelle recette hibiscus citron gingmbre !
          </p>
        </article>

        <article>
          <Flowers />
          <h1>ibahis · fleurs</h1>
          <p>
            Fleurs d’hibiscus séchées
          </p>
        </article>
      </main>

      <footer>
        <p>
          Vous êtes intéressé par nos produits ? <AnchorLink className="button-link" to="/products">acheter en ligne</AnchorLink>
        </p>
      </footer>
    </section>
  )
}